.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes slide-bottom-to-top {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.slider-bottom-to-top {
  animation: slide-bottom-to-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  display: none !important;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  display: none !important;
}

.truncate-text {
  display: block;
  width: 100%; /* Ya jitni width chahiye */
  white-space: nowrap; /* Ek line par limit karta hai */
  overflow: hidden; /* Overflow text ko hide karta hai */
  text-overflow: ellipsis; /* Extra text ko "..." dikhata hai */
}
