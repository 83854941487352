@tailwind base;
@tailwind components;
@tailwind utilities;
body {
  margin: 0;
  font-family: "Dubai";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-heading font-bold;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.hFUZQZ {
  width: 8rem;
  height: 8rem;
  padding: 0px;
  flex-shrink: 0;
  -webkit-box-flex: 0;
  flex-grow: 0;
  background-color: rgb(255, 255, 255);
}
