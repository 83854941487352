/* Base Styles */
.privacy-policy-container {
  height: auto;
  width: 100%;
  background-color: #f5f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heading {
  color: black;
  font-weight: bold;
}

.over-container-1 {
  margin: 20px 200px;
  font-family: 'IBM Plex Sans Arabic', sans-serif !important;
  font-size: 21px;
  font-weight: 600;
  line-height: 27px;
}

.over-container-1 ul {
  list-style-type: disc;
  color: #2e2d2c;
  margin: 30px;
  line-height: 1.4;
  font-weight: 600;
  font-size: 15px;
  padding-left: 50px;
}

.over-container-2,
.over-container-4 {
  margin: 80px 200px;
  font-family: 'IBM Plex Sans Arabic', sans-serif !important;
  font-size: 21px;
  font-weight: 600;
  line-height: 27px;
}

.over-container-3 {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 30px;
  font-family: 'IBM Plex Sans Arabic', sans-serif !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  box-sizing: border-box;
  color: black;
}

.over-container-5,
.over-container-6,
.over-container-7,
.over-container-8,
.over-container-9,
.over-container-10 {
  font-family: 'IBM Plex Sans Arabic', sans-serif !important;
  font-size: 17px;
  font-weight: 500;
  line-height: 27px;
  margin-left: 8px;
  margin-top: 20px;
}

.heading {
  margin-top: 15px;
  margin-bottom: 8px;
  font-size: 20px;
}

.paragraph-privacy,
.paragraph-privacy-2,
.paragraph-privacy-3,
.paragraph-privacy-4,
.paragraph-privacy-5,
.paragraph-privacy-6 {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 40px;
  font-family: 'IBM Plex Sans Arabic', sans-serif !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  box-sizing: border-box;
  color: black;
}

.paragraph-privacy-2 {
  margin-left: 60px;
}

.paragraph-privacy-3 {
  margin-left: 60px;
  margin-top: 10px;
  font-weight: 600;
}

.paragraph-privacy-4,
.paragraph-privacy-5 {
  margin-left: 40px;
  margin-top: 35px;
  font-weight: 500;
  line-height: 40px;
}

.paragraph-privacy-6 {
  margin-left: 60px;
  margin-right: 20px;
  margin-top: 10px;
  font-weight: 500;
  line-height: 30px;
}

.strong {
  padding: 5px;
}

.list {
  margin-left: 30px;
}

/* Responsive Styles */
@media (max-width: 1024px) {

  /* For Tablets */
  .over-container-1,
  .over-container-2,
  .over-container-4 {
    margin: 20px 80px;
    font-size: 18px;
  }

  .over-container-5,
  .over-container-6,
  .over-container-7,
  .over-container-8,
  .over-container-9,
  .over-container-10 {
    font-size: 16px;
    margin-left: 16px;
  }

  .paragraph-privacy,
  .paragraph-privacy-2,
  .paragraph-privacy-3,
  .paragraph-privacy-4,
  .paragraph-privacy-5,
  .paragraph-privacy-6 {
    font-size: 15px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .heading {
    font-size: 18px;
  }
}

@media (max-width: 768px) {

  /* For Mobile */
  .privacy-policy-container {
    height: auto;
    padding: 15px;
  }

  .over-container-1,
  .over-container-2,
  .over-container-4 {
    margin: 20px;
    font-size: 16px;
  }

  .over-container-5,
  .over-container-6,
  .over-container-7,
  .over-container-8,
  .over-container-9,
  .over-container-10 {
    font-size: 15px;
    margin-left: 8px;
  }

  .paragraph-privacy,
  .paragraph-privacy-2,
  .paragraph-privacy-3,
  .paragraph-privacy-4,
  .paragraph-privacy-5,
  .paragraph-privacy-6 {
    font-size: 14px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .heading {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .paragraph-privacy-4,
  .paragraph-privacy-5 {
    margin-left: 20px;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {

  /* For Very Small Screens */
  .privacy-policy-container {
    padding: 10px;
  }

  .over-container-1,
  .over-container-2,
  .over-container-4 {
    font-size: 14px;
    margin: 10px;
  }

  .over-container-5,
  .over-container-6,
  .over-container-7,
  .over-container-8,
  .over-container-9,
  .over-container-10 {
    font-size: 14px;
    margin-left: 8px;
  }

  .paragraph-privacy,
  .paragraph-privacy-2,
  .paragraph-privacy-3,
  .paragraph-privacy-4,
  .paragraph-privacy-5,
  .paragraph-privacy-6 {
    font-size: 13px;
    margin-left: 8px;
    margin-right: 8px;
  }

  .heading {
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .list {
    margin-left: 15px;
  }
}