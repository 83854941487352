/* General Styles */
.terms-container {
  height: 100px; /* Set height to 250px */
  background-color: white; /* Light grey background */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.heading {
  color: black; /* Font color for heading */
  font-weight: bold; /* Make the text bold */
}

.font-size {
  font-weight: 900;
  font-size: 3rem; 
  text-align: left;
  line-height: 50px;
  color: #333; /* Darker text for better readability */
}

.toc-container {
  width: 80%;
  text-align: left;
  line-height: 30px;
  border: 1px solid #5e7baa;
  border-radius: 5px;
  background-color: white;
  padding: 20px;
  color: #333; /* Dark text for readability */
}

.terms-container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333; /* Dark text color for contrast */
}

.overview-container {
  padding: 20px;
  color: #333; /* Dark text color for readability */
  background-color: white; /* Light background */
  border-radius: 8px;
  margin: 30px 150px;
}

.overview-container-2,
.overview-container-3,
.overview-container-4,
.overview-container-5,
.overview-container-6,
.overview-container-7,
.overview-container-8,
.overview-container-9,
.overview-container-10,
.overview-container-11,
.overview-container-12 {
  padding: 20px;
  color: #333; /* Dark text color for readability */
  background-color: white; /* Light background */
  border-radius: 8px;
  margin: 30px 150px;
}

.overview-container-6 ul,
.overview-container-7 ul,
.overview-container-11 ul,
.overview-container-12 ul {
  list-style-type: disc;
  color: #333; /* Dark text for readability */
  line-height: 1.8;
  padding-left: 20px;
}

/* Responsive Design */
@media (max-width: 1024px) {
  .overview-container,
  .overview-container-2,
  .overview-container-3,
  .overview-container-4,
  .overview-container-5,
  .overview-container-6,
  .overview-container-7,
  .overview-container-8,
  .overview-container-9,
  .overview-container-10,
  .overview-container-11,
  .overview-container-12 {
    margin: 30px 50px; /* Reduce side margins on medium screens */
  }

  h2 {
    font-size: 1.5rem; /* Smaller font for headings */
    color: #333; /* Dark color for heading text */
  }

  .paragraph {
    font-size: 16px; /* Adjust paragraph font size */
    color: #333; /* Dark text color for paragraphs */
  }

  .following,
  .precaution {
    font-size: 14px; /* Adjust font size for smaller text */
    color: #333; /* Dark text color */
  }
}

@media (max-width: 768px) {
  .terms-container {
    height: auto; /* Auto height for small screens */
    padding: 10px; /* Less padding */
  }

  .font-size {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }

  .overview-container,
  .overview-container-2,
  .overview-container-3,
  .overview-container-4,
  .overview-container-5,
  .overview-container-6,
  .overview-container-7,
  .overview-container-8,
  .overview-container-9,
  .overview-container-10,
  .overview-container-11,
  .overview-container-12 {
    margin: 20px 20px; /* Reduce side margins */
  }

  h2 {
    font-size: 1.2rem; /* Smaller headings */
  }

  .paragraph {
    font-size: 14px; /* Reduce paragraph font size */
  }

  ul {
    padding-left: 15px; /* Reduce list padding */
  }

  .following,
  .precaution {
    font-size: 12px; /* Adjust text size for small screens */
  }
}

@media (max-width: 480px) {
  .terms-container {
    height: auto; /* Auto height for small screens */
    padding: 10px 5px; /* Less padding */
  }

  .font-size {
    font-size: 1.5rem; /* Further reduce font size */
  }

  .overview-container,
  .overview-container-2,
  .overview-container-3,
  .overview-container-4,
  .overview-container-5,
  .overview-container-6,
  .overview-container-7,
  .overview-container-8,
  .overview-container-9,
  .overview-container-10,
  .overview-container-11,
  .overview-container-12 {
    margin: 15px 5px; /* Reduce margins even further for small screens */
  }

  h2 {
    font-size: 1rem; /* Very small headings for small screens */
  }

  .paragraph {
    font-size: 12px; /* Very small paragraph text */
  }

  .following,
  .precaution {
    font-size: 10px; /* Very small text for mobile */
  }

  ul {
    padding-left: 10px; /* Even less padding for mobile */
  }
}
